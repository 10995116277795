import {Numeros,Opinion} from './nousContacter.js'
import './contacts.css'

export default function Contacts() {
    return <div id="contacts">
        <Numeros/>
        <Opinion/>
      </div>
  }

